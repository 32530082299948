import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { UpdateCoachDetails } from "api/secureApi.ts";
import { GetCookie } from "api/jwt.js";
import { ReactComponent as UmbrellaIcon } from "feather-icons/dist/icons/umbrella.svg";
import { ReactComponent as UserCheckIcon } from "feather-icons/dist/icons/user-check.svg";
import { ReactComponent as ActivityIcon } from "feather-icons/dist/icons/activity.svg";
import { ReactComponent as SvgDotPatternIcon } from "../../../images/svg-decorator-blob-2.svg";
import {
  Box,
  Button,
  ButtonGroup,
  FocusLock,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Select,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { CheckCircleIcon, EditIcon } from "@chakra-ui/icons";

const StyledUmbrellaIcon = tw(UmbrellaIcon)`tw-inline-flex`;
const StyledUserCheckIcon = tw(UserCheckIcon)`tw-inline-flex`;
const StyledActivityIcon = tw(ActivityIcon)`tw-inline-flex`;
const Pill = tw.div`tw-inline-flex tw-text-gray-800 tw-pl-4 tw-pr-4 tw-pb-2 tw-pt-2 tw-rounded tw-font-bold tw-mt-2 tw-mr-3`;
const Subheading = tw.div`tw-relative tw-text-lg lg:tw-text-xl tw-font-semibold tw-inline-block`;
const Description = tw.div`tw-relative tw-mt-2`;
const Attention = tw(
  Description,
)`tw-text-primary-800 tw-font-semibold tw-mt-1 tw-mb-3`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon,
)`tw-absolute tw-bottom-0 tw-right-0 tw-transform tw-translate-y-2/3 tw-translate-x-1/2 -tw-z-10 tw-opacity-50 tw-text-primary-500 tw-fill-current tw-w-32`;
const FieldHeading = tw.div`tw-font-semibold tw-text-sm`;
const SubSectionHeading = tw.div`tw-font-semibold tw-text-lg tw-inline-flex tw-ml-5`;
const FieldValue = tw.div`tw-text-xl tw-font-thin`;
const FieldContainer = tw.div`tw-relative tw-mt-3`;
const InformationContainerInner = tw.div`tw-absolute tw-bg-gray-200 tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-rounded-xl`;
const SubSection = tw.div`tw-rounded-xl tw-bg-gray-100 tw-block tw-drop-shadow-sm tw-border-gray-400 tw-border-2 lg:tw-inline-flex lg:tw-mb-0 tw-mb-5`;
const SubSectionTitleBlock = tw.div`lg:tw-w-[50%] tw-bg-gray-300 tw-rounded-t-lg lg:tw-rounded-l-lg lg:tw-rounded-l-lg lg:tw-rounded-tr-none tw-p-4`;
const SubSectionContent = tw.div`tw-py-4 tw-px-4`;
const CardContainer = tw.div`tw-mt-5 lg:tw-grid lg:tw-grid-cols-2 tw-grid-flow-row tw-gap-4`;
const SubSectionTitleBlockInner = tw.div``;

export default ({ memberDetails, setMemberDetails, isEditable }) => {
  const [readyToUpdate, setReadyToUpdate] = useState(false);
  const toast = useToast();
  const [isJuniorCoach, setIsJuniorCoach] = useState(
    memberDetails == null ? null : memberDetails.isJuniorCoach,
  );
  const [isActivator, setIsActivator] = useState(
    memberDetails == null ? null : memberDetails.isActivator,
  );
  const [isCoach, setIsCoach] = useState(memberDetails.isCoach);
  const [coachingLevel, setCoachingLevel] = useState(memberDetails.coachLevel);
  const [btfMembershipNumber, setBtfMembershipNumber] = useState(
    memberDetails.btfMembershipNumber,
  );
  const [btfCoachCertificateNumber, setBtfCoachCertificateNumber] = useState(
    memberDetails == null ? null : memberDetails.btfCoachCertificateNumber,
  );
  const [firstAidCertificateNumber, setFirstAidCertificateNumber] = useState(
    memberDetails.firstAidCertificateNumber,
  );
  const [firstAidCertificateExpiryDate, setFirstAidCertificateExpiryDate] =
    useState(memberDetails.firstAidCertificateExpiryDate);
  const [dbsCertificateNumber, setDbsCertificateNumber] = useState(
    memberDetails.dbsCertificateNumber,
  );
  const [dbsCertificateExpiryDate, setDbsCertificateExpiryDate] = useState(
    memberDetails.dbsCertificateExpiryDate,
  );
  const [safeguardingCertificateNumber, setSafeguardingCertificateNumber] =
    useState(memberDetails.safegaurdingCertificateNumber);
  const [
    safeguardingCertificateExpiryDate,
    setSafeguardingCertificateExpiryDate,
  ] = useState(memberDetails.safegaurdingCertificateExpiry);

  useEffect(() => {
    if (readyToUpdate) {
      saveDetails();
    }
  }, [
    isJuniorCoach,
    isActivator,
    isCoach,
    coachingLevel,
    btfMembershipNumber,
    btfCoachCertificateNumber,
    firstAidCertificateNumber,
    firstAidCertificateExpiryDate,
    dbsCertificateNumber,
    dbsCertificateExpiryDate,
    safeguardingCertificateNumber,
    safeguardingCertificateExpiryDate,
  ]);

  const saveDetails = function () {
    UpdateCoachDetails(GetCookie("idToken"), memberDetails.membershipNumber, {
      isJuniorCoach: isJuniorCoach,
      isActivator: isActivator,
      isCoach: isCoach,
      coachLevel: coachingLevel,
      btfMembershipNumber: btfMembershipNumber,
      btfCoachCertificateNumber: btfCoachCertificateNumber,
      firstAidCertificateNumber: firstAidCertificateNumber,
      firstAidCertificateExpiryDate:
        firstAidCertificateExpiryDate == null
          ? null
          : firstAidCertificateExpiryDate.split("T")[0],
      dbsCertificateNumber: dbsCertificateNumber,
      dbsCertificateExpiryDate:
        dbsCertificateExpiryDate == null
          ? null
          : dbsCertificateExpiryDate.split("T")[0],
      safegaurdingCertificateNumber: safeguardingCertificateNumber,
      safegaurdingCertificateExpiry:
        safeguardingCertificateExpiryDate == null
          ? null
          : safeguardingCertificateExpiryDate.split("T")[0],
    })
      .then((response) => {
        setMemberDetails(response);
        //Success - show toast
        toast({
          title: "Successfully updated 🏊🚴🏃",
          status: "success",
          isClosable: true,
        });
      })
      .catch((err) => {
        //Error - todo reset variables
        toast({
          title: "Something went wrong - refresh the page and try again!",
          status: "error",
          isClosable: true,
        });
      });
  };

  const dateDisplayOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  const SelectInput = React.forwardRef((props, ref) => {
    return (
      <FormControl>
        <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
        <Select ref={ref} id={props.id} {...props}>
          <option value="Activator">Activator</option>
          <option value="BTF Level 1">BTF Level 1</option>
          <option value="BTF Level 2">BTF Level 2</option>
          <option value="BTF Level 3">BTF Level 3</option>
          <option value="ASA Level 1">ASA Level 1</option>
          <option value="ASA Level 2">ASA Level 2</option>
          <option value="ASA Level 3">ASA Level 3</option>
          <option value="PT Level 1">PT Level 1</option>
          <option value="PT Level 2">PT Level 2</option>
          <option value="PT Level 3">PT Level 3</option>
        </Select>
      </FormControl>
    );
  });

  const TextInput = React.forwardRef((props, ref) => {
    return (
      <FormControl>
        <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
        <Input ref={ref} id={props.id} {...props} />
      </FormControl>
    );
  });

  const CoachLevelForm = ({ firstFieldRef, onCancel }) => {
    return (
      <Stack spacing={4}>
        <SelectInput
          id="coachingLevel"
          label="Coaching Level"
          placeholder="Select option"
        >
          <option value="Activator">Activator</option>
          <option value="BTF Level 1">BTF Level 1</option>
          <option value="BTF Level 2">BTF Level 2</option>
          <option value="BTF Level 3">BTF Level 3</option>
          <option value="ASA Level 1">ASA Level 1</option>
          <option value="ASA Level 2">ASA Level 2</option>
          <option value="ASA Level 3">ASA Level 3</option>
          <option value="PT Level 1">PT Level 1</option>
          <option value="PT Level 2">PT Level 2</option>
          <option value="PT Level 3">PT Level 3</option>
        </SelectInput>
        <ButtonGroup display="flex" justifyContent="flex-end">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={() => {
              setReadyToUpdate(true);
              setCoachingLevel(document.getElementById("coachingLevel").value);
            }}
          >
            Save
          </Button>
        </ButtonGroup>
      </Stack>
    );
  };

  const CoachLevel = () => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const firstFieldRef = React.useRef(null);

    return (
      <>
        <Box fontSize="xl" fontWeight="thin" display="inline-block" mr={3}>
          {coachingLevel === undefined ? " - " : coachingLevel}
        </Box>
        <Popover
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          closeOnBlur={false}
        >
          {isEditable && (
            <PopoverTrigger>
              <IconButton size="xs" tw="tw-bg-gray-400" icon={<EditIcon />} />
            </PopoverTrigger>
          )}
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <CoachLevelForm
                firstFieldRef={firstFieldRef}
                onCancel={onClose}
              />
            </FocusLock>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  const TextForm = ({ defaultValue, id, label, onSubmit, onCancel }) => {
    return (
      <Stack spacing={4}>
        <TextInput label={label} id={id} defaultValue={defaultValue} />
        <ButtonGroup display="flex" justifyContent="flex-end">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={onSubmit}>
            Save
          </Button>
        </ButtonGroup>
      </Stack>
    );
  };

  const BtfMembershipNumber = () => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const firstFieldRef = React.useRef(null);

    return (
      <>
        <Box fontSize="xl" fontWeight="thin" display="inline-block" mr={3}>
          {btfMembershipNumber === undefined ? " - " : btfMembershipNumber}
        </Box>
        <Popover
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          closeOnBlur={false}
        >
          {isEditable && (
            <PopoverTrigger>
              <IconButton size="xs" tw="tw-bg-gray-400" icon={<EditIcon />} />
            </PopoverTrigger>
          )}
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <TextForm
                defaultValue={memberDetails.btfMembershipNumber}
                id="btfMembershipNumber"
                label="BTF membership Number"
                onSubmit={() => {
                  setReadyToUpdate(true);
                  setBtfMembershipNumber(
                    document.getElementById("btfMembershipNumber").value,
                  );
                }}
                onCancel={onClose}
              />
            </FocusLock>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  const BtfCoachCertificate = () => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const firstFieldRef = React.useRef(null);

    return (
      <>
        <Box fontSize="xl" fontWeight="thin" display="inline-block" mr={3}>
          {btfCoachCertificateNumber === undefined
            ? " - "
            : btfCoachCertificateNumber}
        </Box>
        <Popover
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          closeOnBlur={false}
        >
          {isEditable && (
            <PopoverTrigger>
              <IconButton size="xs" tw="tw-bg-gray-400" icon={<EditIcon />} />
            </PopoverTrigger>
          )}
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <TextForm
                defaultValue={memberDetails.btfCoachCertificateNumber}
                id="btfCoachCertificateNumber"
                label="BTF Coach / Activator Certificate Number"
                onSubmit={() => {
                  setReadyToUpdate(true);
                  setBtfCoachCertificateNumber(
                    document.getElementById("btfCoachCertificateNumber").value,
                  );
                }}
                onCancel={onClose}
              />
            </FocusLock>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  const CertificateForm = ({
    defaultCertValue,
    defaultExpiryValue,
    certID,
    expiryID,
    certLabel,
    certExpiryLabel,
    onSubmit,
    onCancel,
  }) => {
    return (
      <Stack spacing={4}>
        <TextInput
          label={certLabel}
          id={certID}
          defaultValue={defaultCertValue}
        />
        <TextInput
          label={certExpiryLabel}
          id={expiryID}
          defaultValue={defaultExpiryValue}
          type="date"
        />
        <ButtonGroup display="flex" justifyContent="flex-end">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={onSubmit}>
            Save
          </Button>
        </ButtonGroup>
      </Stack>
    );
  };

  const FirstAidDetails = () => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const firstFieldRef = React.useRef(null);

    return (
      <>
        <Box fontSize="xl" fontWeight="thin" display="inline-block" mr={3}>
          {firstAidCertificateNumber == null
            ? " - "
            : firstAidCertificateNumber}
        </Box>
        <Popover
          placement="top"
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          closeOnBlur={false}
        >
          {isEditable && (
            <PopoverTrigger>
              <IconButton size="xs" tw="tw-bg-gray-400" icon={<EditIcon />} />
            </PopoverTrigger>
          )}
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <CertificateForm
                defaultCertValue={firstAidCertificateNumber}
                defaultExpiryValue={firstAidCertificateExpiryDate}
                certLabel={"Certificate Number"}
                certExpiryLabel={"Certificate Expiry Date"}
                certID={"firstAidCertificateNumber"}
                expiryID={"firstAidCertificateExpiryDate"}
                onSubmit={() => {
                  setReadyToUpdate(true);
                  setFirstAidCertificateNumber(
                    document.getElementById("firstAidCertificateNumber").value,
                  );
                  setFirstAidCertificateExpiryDate(
                    document.getElementById("firstAidCertificateExpiryDate")
                      .value,
                  );
                }}
                onCancel={onClose}
              />
            </FocusLock>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  const DBSDetails = () => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const firstFieldRef = React.useRef(null);

    return (
      <>
        <Box fontSize="xl" fontWeight="thin" display="inline-block" mr={3}>
          {dbsCertificateNumber == null ? " - " : dbsCertificateNumber}
        </Box>
        <Popover
          placement="top"
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          closeOnBlur={false}
        >
          {isEditable && (
            <PopoverTrigger>
              <IconButton size="xs" tw="tw-bg-gray-400" icon={<EditIcon />} />
            </PopoverTrigger>
          )}
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <CertificateForm
                defaultCertValue={dbsCertificateNumber}
                defaultExpiryValue={dbsCertificateExpiryDate}
                certLabel={"Certificate Number"}
                certExpiryLabel={"Certificate Expiry Date"}
                certID={"dbsCertificateNumber"}
                expiryID={"dbsCertificateExpiryDate"}
                onSubmit={() => {
                  setReadyToUpdate(true);
                  setDbsCertificateNumber(
                    document.getElementById("dbsCertificateNumber").value,
                  );
                  setDbsCertificateExpiryDate(
                    document.getElementById("dbsCertificateExpiryDate").value,
                  );
                }}
                onCancel={onClose}
              />
            </FocusLock>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  const SafeguardingDetails = () => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const firstFieldRef = React.useRef(null);

    return (
      <>
        <Box fontSize="xl" fontWeight="thin" display="inline-block" mr={3}>
          {safeguardingCertificateNumber == null
            ? " - "
            : safeguardingCertificateNumber}
        </Box>
        <Popover
          placement="top"
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          closeOnBlur={false}
        >
          {isEditable && (
            <PopoverTrigger>
              <IconButton size="xs" tw="tw-bg-gray-400" icon={<EditIcon />} />
            </PopoverTrigger>
          )}
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <CertificateForm
                defaultCertValue={safeguardingCertificateNumber}
                defaultExpiryValue={safeguardingCertificateExpiryDate}
                certLabel={"Certificate Number"}
                certExpiryLabel={"Certificate Expiry Date"}
                certID={"sgCertificateNumber"}
                expiryID={"sgCertificateExpiryDate"}
                onSubmit={() => {
                  setReadyToUpdate(true);
                  setSafeguardingCertificateNumber(
                    document.getElementById("sgCertificateNumber").value,
                  );
                  setSafeguardingCertificateExpiryDate(
                    document.getElementById("sgCertificateExpiryDate").value,
                  );
                }}
                onCancel={onClose}
              />
            </FocusLock>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  return (
    <>
      {isEditable && <SvgDotPattern1 />}
      {isEditable && <InformationContainerInner></InformationContainerInner>}
      <Subheading>Coach / Activator Details</Subheading>

      {memberDetails.coachStatus !== "ACTIVE" && (
        <Attention id="coachAlert">&#9888; Attention Required</Attention>
      )}

      <Grid mt={"2"}>
        <FieldContainer>
          <FormControl display="flex" alignItems="center">
            <FieldHeading>Are you a coach or an activator?</FieldHeading>
          </FormControl>
          <Button
            tw="tw-mr-2 tw-mt-2"
            isDisabled={!isEditable}
            colorScheme={isCoach ? "red" : "black"}
            variant={isCoach ? "solid" : "outline"}
            size="lg"
            rightIcon={isCoach ? <CheckCircleIcon /> : null}
            onClick={(e) => {
              setReadyToUpdate(true);
              setIsCoach(true);
              setIsActivator(false);
            }}
          >
            Coach
          </Button>
          <Button
            tw="tw-mt-2"
            isDisabled={!isEditable}
            colorScheme={isActivator ? "red" : "black"}
            variant={isActivator ? "solid" : "outline"}
            size="lg"
            rightIcon={isActivator ? <CheckCircleIcon /> : null}
            onClick={(e) => {
              setReadyToUpdate(true);
              setIsCoach(false);
              setIsActivator(true);
            }}
          >
            Activator
          </Button>
        </FieldContainer>

        <FieldContainer>
          <FormControl display="flex" alignItems="center">
            <FieldHeading>Are you a coach/activator for juniors?</FieldHeading>
          </FormControl>
          <Button
            tw="tw-mr-2 tw-mt-2"
            isDisabled={!isEditable}
            colorScheme={isJuniorCoach ? "red" : "black"}
            variant={isJuniorCoach ? "solid" : "outline"}
            size="lg"
            rightIcon={isJuniorCoach ? <CheckCircleIcon /> : null}
            onClick={(e) => {
              setReadyToUpdate(true);
              setIsJuniorCoach(true);
            }}
          >
            Yes
          </Button>
          <Button
            tw="tw-mt-2"
            isDisabled={!isEditable}
            colorScheme={!isJuniorCoach ? "red" : "black"}
            variant={!isJuniorCoach ? "solid" : "outline"}
            size="lg"
            rightIcon={!isJuniorCoach ? <CheckCircleIcon /> : null}
            onClick={(e) => {
              setReadyToUpdate(true);
              setIsJuniorCoach(false);
            }}
          >
            No
          </Button>
        </FieldContainer>
      </Grid>

      <FieldContainer>
        <FieldHeading>Coaching Level</FieldHeading>
        <CoachLevel />
      </FieldContainer>

      <FieldContainer>
        <FieldHeading>BTF membership Number</FieldHeading>
        <BtfMembershipNumber />
      </FieldContainer>

      <FieldContainer>
        <FieldHeading>BTF Coach / Activator Certificate Number</FieldHeading>
        <BtfCoachCertificate />
      </FieldContainer>

      <CardContainer>
        {isCoach && (
          <SubSection>
            {/* FIRST AID */}
            <SubSectionTitleBlock>
              <SubSectionTitleBlockInner>
                <StyledActivityIcon className="icon" />
                <SubSectionHeading>First Aid</SubSectionHeading>
              </SubSectionTitleBlockInner>
              {memberDetails.firstAidStatus === "ACTIVE" && (
                <Pill style={{ backgroundColor: "#c1f0b2" }}>ACTIVE</Pill>
              )}
              {memberDetails.firstAidStatus === "EXPIRING" && (
                <Pill style={{ backgroundColor: "#f5da64" }}>EXPIRING</Pill>
              )}
              {memberDetails.firstAidStatus === "EXPIRED" && (
                <Pill style={{ backgroundColor: "#f0c1b2" }}>EXPIRED</Pill>
              )}
            </SubSectionTitleBlock>

            <SubSectionContent>
              <FieldContainer>
                <FieldHeading>Certificate Number</FieldHeading>
                <FirstAidDetails />
              </FieldContainer>
              <FieldContainer>
                <FieldHeading>Certificate Expiry Date</FieldHeading>
                <FieldValue id="firstAidCertificateExpiryDisplay">
                  {firstAidCertificateExpiryDate === null
                    ? " - "
                    : new Date(
                        firstAidCertificateExpiryDate,
                      ).toLocaleDateString("en-GB", dateDisplayOptions)}
                </FieldValue>
              </FieldContainer>
            </SubSectionContent>
          </SubSection>
        )}

        {isJuniorCoach && (
          <SubSection>
            {/* DBS */}
            <SubSectionTitleBlock>
              <SubSectionTitleBlockInner>
                <StyledUserCheckIcon className="icon" />
                <SubSectionHeading>DBS Check</SubSectionHeading>
              </SubSectionTitleBlockInner>
              {memberDetails.dbsStatus === "ACTIVE" && (
                <Pill style={{ backgroundColor: "#c1f0b2" }}>ACTIVE</Pill>
              )}
              {memberDetails.dbsStatus === "EXPIRING" && (
                <Pill style={{ backgroundColor: "#f5da64" }}>EXPIRING</Pill>
              )}
              {memberDetails.dbsStatus === "EXPIRED" && (
                <Pill style={{ backgroundColor: "#f0c1b2" }}>EXPIRED</Pill>
              )}
            </SubSectionTitleBlock>

            <SubSectionContent>
              <FieldContainer>
                <FieldHeading>Certificate Number</FieldHeading>
                <DBSDetails />
              </FieldContainer>
              <FieldContainer>
                <FieldHeading>Certificate Expiry Date</FieldHeading>
                <FieldValue>
                  {dbsCertificateExpiryDate === null
                    ? " - "
                    : new Date(dbsCertificateExpiryDate).toLocaleDateString(
                        "en-GB",
                        dateDisplayOptions,
                      )}
                </FieldValue>
              </FieldContainer>
            </SubSectionContent>
          </SubSection>
        )}

        {(isCoach || isActivator) && (
          <SubSection>
            {/* SAFE GAURDING */}
            <SubSectionTitleBlock>
              <SubSectionTitleBlockInner>
                <StyledUmbrellaIcon className="icon" />
                {isJuniorCoach && (
                  <SubSectionHeading>
                    Safe Guarding for Children
                  </SubSectionHeading>
                )}
                {!isJuniorCoach && (
                  <SubSectionHeading>Safe Guarding</SubSectionHeading>
                )}
              </SubSectionTitleBlockInner>
              {memberDetails.safegaurdingStatus === "ACTIVE" && (
                <Pill style={{ backgroundColor: "#c1f0b2" }}>ACTIVE</Pill>
              )}
              {memberDetails.safegaurdingStatus === "EXPIRING" && (
                <Pill style={{ backgroundColor: "#f5da64" }}>EXPIRING</Pill>
              )}
              {memberDetails.safegaurdingStatus === "EXPIRED" && (
                <Pill style={{ backgroundColor: "#f0c1b2" }}>EXPIRED</Pill>
              )}
            </SubSectionTitleBlock>

            <SubSectionContent>
              <FieldContainer>
                <FieldHeading>Certificate Number</FieldHeading>
                <SafeguardingDetails />
              </FieldContainer>
              <FieldContainer>
                <FieldHeading>Certificate Expiry Date</FieldHeading>
                <FieldValue>
                  {safeguardingCertificateExpiryDate === null
                    ? " - "
                    : new Date(
                        safeguardingCertificateExpiryDate,
                      ).toLocaleDateString("en-GB", dateDisplayOptions)}
                </FieldValue>
              </FieldContainer>
            </SubSectionContent>
          </SubSection>
        )}
      </CardContainer>
    </>
  );
};
