import React, { useState, useCallback } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { Button, useToast } from "@chakra-ui/react";
import { ContactUs } from "api/openApi.ts";

const Container = tw.div`tw-relative`;
const Content = tw.div`tw-max-w-screen-xl tw-mx-auto tw-py-20 lg:tw-py-24`;

const FormContainer = styled.div`
  ${tw`tw-p-10 sm:tw-p-12 md:tw-p-16 tw-bg-primary-900 tw-text-gray-100 tw-rounded-lg tw-relative`}
  form {
    ${tw`tw-mt-4`}
  }
  h2 {
    ${tw`tw-text-3xl sm:tw-text-4xl tw-font-bold`}
  }
  input,
  textarea {
    ${tw`tw-w-full tw-bg-transparent tw-text-gray-100 tw-text-base tw-font-medium tw-tracking-wide tw-border-b-2 tw-py-2 tw-text-gray-100 hocus:tw-border-pink-400 focus:tw-outline-none`};

    ::placeholder {
      ${tw`tw-text-gray-500`}
    }
  }
`;

const Paragraph = tw.p`tw-text-lg tw-pt-2`;

const TwoColumn = tw.div`tw-flex tw-flex-col sm:tw-flex-row tw-justify-between`;
const Column = tw.div`sm:tw-w-5/12 tw-flex tw-flex-col`;
const InputContainer = tw.div`tw-relative tw-py-5 tw-mt-6`;
const Label = tw.label`tw-absolute tw-top-0 tw-left-0 tw-tracking-wide tw-font-semibold tw-text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`tw-h-24 sm:tw-h-full tw-resize-none`;
const ChakraButton = tw(
  Button,
)`tw-bg-primary-600 tw-shadow-lg tw-uppercase tw-mt-4`;
const SvgDotPattern1 = tw(
  SvgDotPatternIcon,
)`tw-absolute tw-bottom-0 tw-right-0 tw-transform tw-translate-y-1/2 tw-translate-x-1/2 -tw-z-10 tw-opacity-50 tw-text-primary-500 tw-fill-current tw-w-24`;

export default () => {
  const toast = useToast();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [token, setToken] = useState("");

  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(null);

  const onVerify = useCallback(
    (newToken) => {
      setToken(newToken);
    },
    [refreshReCaptcha],
  );

  function displayToast(title, status) {
    toast({
      title: title,
      status: status,
      isClosable: true,
    });
  }

  const submitContactUs = function () {
    setSubmitting(true);

    const request = {
      name: document.getElementById("name-input").value,
      emailAddress: document.getElementById("email-input").value,
      message: document.getElementById("message-input").value,
      token: token,
    };

    ContactUs(request)
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
      })
      .catch((err) => {
        displayToast("Something went wrong - try again!", "error");
        setSubmitting(false);
      });
    setRefreshReCaptcha((r) => !r);
  };

  return (
    <Container>
      <Content>
        <FormContainer>
          {success == null && (
            <div id="contactusform" tw="tw-mx-auto tw-max-w-4xl">
              <h2>Contact us</h2>
              <form action="#">
                <TwoColumn>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="name-input">Your Name</Label>
                      <Input
                        onClick={(e) => {
                          setRefreshReCaptcha((r) => !r);
                        }}
                        id="name-input"
                        type="text"
                        name="name"
                        placeholder="John Doe"
                      />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="email-input">Your Email Address</Label>
                      <Input
                        onClick={(e) => {
                          setRefreshReCaptcha((r) => !r);
                        }}
                        id="email-input"
                        type="email"
                        name="email"
                        placeholder="john@mail.com"
                      />
                    </InputContainer>
                  </Column>
                  <Column>
                    <InputContainer tw="tw-flex-1">
                      <Label htmlFor="name-input">Your Message</Label>
                      <TextArea
                        onClick={(e) => {
                          setRefreshReCaptcha((r) => !r);
                        }}
                        id="message-input"
                        name="message"
                        placeholder="Hi BadTri, your club looks amazing - can I join you on Wednesday morning swim?"
                      />
                    </InputContainer>
                  </Column>
                </TwoColumn>

                <ChakraButton
                  colorScheme="red"
                  size="lg"
                  onClick={submitContactUs}
                  isLoading={submitting}
                  loadingText="SUBMIT"
                  spinnerPlacement="start"
                >
                  Submit
                </ChakraButton>
              </form>
            </div>
          )}
          {success == true && (
            <div id="successconfirmation" tw="tw-mx-auto tw-max-w-4xl tw-py-24">
              <h2>Thanks for contacting us</h2>
              <Paragraph>
                Your message landed in our inbox like a perfectly executed
                triathlon transition! 🏊‍♀️🚴‍♂️🏃‍♂️
              </Paragraph>
            </div>
          )}

          <SvgDotPattern1 />
        </FormContainer>
      </Content>
      <GoogleReCaptchaProvider reCaptchaKey="6LfHfRApAAAAAPIC7KdWVcmGrH5Qk-2VvbLasEcg">
        <GoogleReCaptcha
          action="ContactUs"
          onVerify={onVerify}
          refreshReCaptcha={refreshReCaptcha}
        />
      </GoogleReCaptchaProvider>
    </Container>
  );
};
