import React from "react";
import tw from "twin.macro";
import { Container } from "components/misc/Layouts";
import CoachesInfo from "./CoachesInfo.js";

const InformationContainer = tw.div`tw-relative tw-w-full tw-bg-gray-100 tw-p-3 lg:tw-p-12 tw-mt-12 tw-rounded-xl tw-drop-shadow-2xl tw-border-gray-900 tw-border-2`;

export default ({ memberDetails, setMemberDetails, isEditable }) => {
  return (
    <Container>
      {isEditable && (
        <InformationContainer>
          <CoachesInfo
            setMemberDetails={setMemberDetails}
            memberDetails={memberDetails}
            isEditable={isEditable}
          ></CoachesInfo>
        </InformationContainer>
      )}
      {!isEditable && (
        <CoachesInfo
          setMemberDetails={setMemberDetails}
          memberDetails={memberDetails}
          isEditable={isEditable}
        ></CoachesInfo>
      )}
    </Container>
  );
};
