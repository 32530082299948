import React, { useState, useCallback } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import illustration from "images/IMG_9341.JPG";
import logo from "images/BADtri_logo_black.svg";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import { AddressAutofill } from "@mapbox/search-js-react";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useRadio,
  useRadioGroup,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowForwardIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { DayMembershipRequest } from "api/openApi.ts";

const SuccessContainer = tw.div`tw-py-20`;
const SuccessMessage = tw.div`tw-text-center`;
const Container = tw.div`tw-max-w-[100%] tw-relative tw-bg-gray-200 tw-bg-primary-800 sm:tw-px-8 sm:tw-py-5 tw-overflow-x-hidden`;
const Content = tw.div`tw-max-w-screen-xl tw-mx-auto md:tw-my-8 tw-bg-white tw-text-gray-900 tw-shadow sm:tw-rounded-lg tw-flex tw-justify-center tw-flex-1`;
const MainContainer = tw.div`lg:tw-w-1/2 xl:tw-w-5/12 tw-p-6 sm:tw-p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`tw-h-16 tw-mx-auto`;
const MainContent = tw.div`tw-mt-12 tw-flex tw-flex-col tw-items-center`;
const Heading = tw.h1`tw-text-2xl xl:tw-text-3xl tw-font-extrabold`;
const FormContainer = tw.div`tw-w-full tw-flex-1 tw-mt-6`;
const Form = tw.form`tw-mx-auto tw-max-w-xs`;
const FormHelperLink = tw(FormHelperText)`tw-underline tw-cursor-pointer`;
const ChakraFormControl = tw(FormControl)`tw-mt-5`;
const IllustrationContainer = tw.div`sm:tw-rounded-r-lg tw-flex-1 tw-bg-purple-100 tw-text-center tw-overflow-hidden tw-hidden lg:tw-flex tw-justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`tw-w-full tw-bg-cover tw-bg-center tw-bg-no-repeat`}
`;

export default ({ logoLinkUrl = "/", illustrationImageSrc = illustration }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [firstNameInvalid, setFirstNameInvalid] = useState(false);
  const [familyName, setFamilyName] = useState("");
  const [familyNameInvalid, setFamilyNameInvalid] = useState(false);
  const [emergencyContactName, setEmergencyContactName] = useState("");
  const [emergencyContactNameInvalid, setEmergencyContactNameInvalid] =
    useState(false);
  const [emergencyContactNumber, setEmergencyContactNumber] = useState("");
  const [emergencyContactNumberInvalid, setEmergencyContactNumberInvalid] =
    useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberInvalid, setPhoneNumberInvalid] = useState(false);
  const [emailAddressInvalid, setEmailAddressInvalid] = useState(false);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressInvalid, setAddressInvalid] = useState(false);
  const [addressLine2, setAddressLine2] = useState("");
  const [postCode, setPostCode] = useState("");
  const [showFormExpanded, setShowFormExpanded] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [agreementInvalid, setAgreementInvalid] = useState(false);
  const [seniorJunior, setSeniorJunior] = useState("");
  const [seniorJuniorInvalid, setSeniorJuniorInvalid] = useState(false);
  const [sessionDetails, setSessionDetails] = useState("");
  const [sessionDetailsInvalid, setSessionDetailsInvalid] = useState(false);
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const onVerify = useCallback(
    (newToken) => {
      setToken(newToken);
    },
    [refreshReCaptcha],
  );

  function RadioCard(props) {
    const { getInputProps, getRadioProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getRadioProps();

    return (
      <Box as="label">
        <input {...input} />
        <Box
          {...checkbox}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="md"
          _checked={{
            borderColor: "red.600",
            borderWidth: "3px",
          }}
          px={10}
          py={2}
        >
          {props.children}
        </Box>
      </Box>
    );
  }

  function Example() {
    const options = ["Senior", "Junior"];

    const { getRootProps, getRadioProps } = useRadioGroup({
      name: "framework",
      defaultValue: seniorJunior,
      onChange: setSeniorJunior,
    });

    const group = getRootProps();

    return (
      <HStack {...group}>
        {options.map((value) => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard key={value} {...radio}>
              {value}
            </RadioCard>
          );
        })}
      </HStack>
    );
  }

  const previousStep = function () {
    setStep(step - 1);
  };

  const nextStep = function () {
    validateStep1();
  };

  const validateStep1 = function () {
    if (firstName === "") {
      setFirstNameInvalid(true);
      return;
    }

    if (familyName === "") {
      setFamilyNameInvalid(true);
      return;
    }

    if (
      emailAddress === "" ||
      !emailAddress.includes("@") ||
      !emailAddress.split("@")[1].includes(".") ||
      !(emailAddress.split("@")[1].split(".")[1].length > 1)
    ) {
      setEmailAddressInvalid(true);
      return;
    }

    if (phoneNumber.length !== 11 || phoneNumber.includes(".")) {
      setPhoneNumberInvalid(true);
      return;
    }

    if (addressLine1 === "" || postCode === "") {
      setAddressInvalid(true);
      return;
    }

    if (agreement === false) {
      setAgreementInvalid(true);
      return;
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setStep(step + 1);
  };

  const submitForm = async () => {
    if (emergencyContactName === "") {
      setEmergencyContactNameInvalid(true);
      return;
    }

    if (
      emergencyContactNumber.length !== 11 ||
      emergencyContactNumber.includes(".")
    ) {
      setEmergencyContactNumberInvalid(true);
      return;
    }

    if (sessionDetails === "") {
      setSessionDetailsInvalid(true);
      return;
    }

    if (seniorJunior === "") {
      setSeniorJuniorInvalid(true);
      return;
    }

    const dayMembershipFormRequest = {
      firstName: firstName,
      familyName: familyName,
      emailAddress: emailAddress,
      phoneNumber: phoneNumber,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      postCode: postCode,
      emergencyContactName: emergencyContactName,
      emergencyContactNumber: emergencyContactNumber,
      seniorJunior: seniorJunior,
      sessionDetails: sessionDetails,
      token: token,
    };

    console.log(dayMembershipFormRequest);
    setButtonDisabled(true);

    setRefreshReCaptcha((r) => !r);

    DayMembershipRequest(dayMembershipFormRequest)
      .then((response) => {
        setStep(step + 1);
      })
      .catch((error) => {
        setButtonDisabled(false);
        toast({
          title: "Something went wrong - try again!",
          status: "error",
          isClosable: true,
        });
      });

    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  const handleRetrieve = useCallback((res) => {
    setShowFormExpanded(true);
    setAddressLine1(res.features[0].properties.address_line1);
    setPostCode(res.features[0].properties.postcode);
    setAddressInvalid(false);
  }, []);

  return (
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>Day Membership Form</Heading>
            <FormContainer>
              <Form>
                {step === 1 && (
                  <>
                    <ChakraFormControl isInvalid={firstNameInvalid}>
                      <FormLabel>First Name</FormLabel>
                      <Input
                        size="lg"
                        type="name"
                        value={firstName}
                        placeholder="First Name"
                        onChange={(e) => {
                          setFirstNameInvalid(e.target.value === "");
                          setFirstName(e.target.value);
                        }}
                      />
                      <FormErrorMessage>
                        First name is required
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <ChakraFormControl isInvalid={familyNameInvalid}>
                      <FormLabel>Surname</FormLabel>
                      <Input
                        size="lg"
                        type="name"
                        value={familyName}
                        placeholder="Surname"
                        onChange={(e) => {
                          setFamilyNameInvalid(e.target.value === "");
                          setFamilyName(e.target.value);
                        }}
                      />
                      <FormErrorMessage>Surname is required</FormErrorMessage>
                    </ChakraFormControl>

                    <ChakraFormControl isInvalid={emailAddressInvalid}>
                      <FormLabel>Email Address</FormLabel>
                      <Input
                        size="lg"
                        value={emailAddress}
                        autoComplete="email"
                        type="email"
                        placeholder="Email Address"
                        onChange={(e) => {
                          if (
                            e.target.value.includes("@") &&
                            e.target.value.split("@")[1].includes(".") &&
                            e.target.value.split("@")[1].split(".")[1].length >
                              1 &&
                            emailAddressInvalid
                          ) {
                            setEmailAddressInvalid(false);
                          }
                          setEmailAddress(e.target.value);
                        }}
                      />
                      <FormErrorMessage>
                        A valid email address is required
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <ChakraFormControl isInvalid={phoneNumberInvalid}>
                      <FormLabel>Mobile Number</FormLabel>
                      <Input
                        size="lg"
                        id="mobileNumber"
                        autoComplete="phone"
                        value={phoneNumber}
                        type="number"
                        placeholder="Mobile Number"
                        onChange={(e) => {
                          if (
                            e.target.value.length === 11 &&
                            !e.target.value.includes(".") &&
                            phoneNumberInvalid
                          ) {
                            setPhoneNumberInvalid(false);
                          }
                          setPhoneNumber(e.target.value);
                        }}
                      />
                      <FormErrorMessage>
                        A valid phone number is required
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <ChakraFormControl isInvalid={addressInvalid}>
                      <FormLabel>Address</FormLabel>

                      <AddressAutofill
                        accessToken="pk.eyJ1IjoibWFya2Fsc3RvbiIsImEiOiJjangzYWt0ZHYwMTdzNDlrdDhtMHl0a3MyIn0.LWzTJORvF1PLlosFIAEyjQ"
                        onRetrieve={handleRetrieve}
                      >
                        <Input
                          tw="tw-mb-2"
                          size="lg"
                          id="addressLine1"
                          placeholder="Start typing..."
                          name="address"
                          autoComplete="address-line1"
                          value={addressLine1}
                          type="text"
                          onChange={(e) => {
                            setAddressLine1(e.target.value);
                          }}
                        />
                      </AddressAutofill>
                      <Input
                        tw="tw-mb-2"
                        style={{ display: showFormExpanded ? "block" : "none" }}
                        size={"lg"}
                        placeholder="Flat, building, floor etc."
                        id="addressLine2"
                        value={addressLine2}
                        name="address"
                        autoComplete="address-line2"
                        type="text"
                        onChange={(e) => {
                          setAddressLine2(e.target.value);
                        }}
                      />
                      <Input
                        style={{ display: showFormExpanded ? "block" : "none" }}
                        id="postCode"
                        size={"lg"}
                        contentEditable={"false"}
                        name="postcode"
                        placeholder="Postcode"
                        type="text"
                        autoComplete="postal-code"
                        readOnly={true}
                        value={postCode}
                      />
                      {!addressInvalid && (
                        <>
                          <FormHelperLink onClick={onOpen}>
                            I can't find my address <ExternalLinkIcon />
                          </FormHelperLink>
                          <FormHelperText>
                            We collect your address to ensure our club is
                            providing sessions in the best locations for its
                            members.
                          </FormHelperText>
                          <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalHeader>Solution 1</ModalHeader>
                              <ModalCloseButton />
                              <ModalBody>
                                Try typing your address starting with your house
                                number. For example type: 12 Example Road, BS6
                                4AA...
                              </ModalBody>
                              <ModalHeader>Solution 2</ModalHeader>
                              <ModalBody>
                                If you still cannot find your address, please
                                enter any address which is nearby you. We wont
                                send you anything, we just collect this
                                information to make sure we're providing
                                sessions in the most suitable locations.
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  colorScheme="red"
                                  mr={3}
                                  onClick={onClose}
                                >
                                  Close
                                </Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                        </>
                      )}
                      <FormErrorMessage>
                        A valid address is required
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <ChakraFormControl isInvalid={agreementInvalid}>
                      <FormLabel></FormLabel>
                      <Checkbox
                        size={"sm"}
                        defaultChecked={agreement}
                        onChange={(e) => {
                          console.log(e.target.checked);
                          setAgreementInvalid(!e.target.checked);
                          setAgreement(e.target.checked);
                        }}
                      >
                        I have read and agree to the club constitution, policies
                        and code of conduct&nbsp;
                        <Link
                          target="_blank"
                          href="/documents"
                          textDecoration={"underline"}
                        >
                          found here
                        </Link>
                        .
                      </Checkbox>
                      <FormErrorMessage>
                        As a day member you must read and agree to the club
                        policies and code of conduct.
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <Button
                      rightIcon={<ArrowForwardIcon />}
                      tw="tw-mt-4 tw-w-full"
                      colorScheme="red"
                      onClick={() => {
                        nextStep();
                      }}
                    >
                      NEXT
                    </Button>
                  </>
                )}
                {step === 2 && (
                  <>
                    <ChakraFormControl isInvalid={emergencyContactNameInvalid}>
                      <FormLabel>Emergency Contact Name</FormLabel>
                      <Input
                        size="lg"
                        value={emergencyContactName}
                        type="name"
                        placeholder="Emergency Contact Name"
                        onChange={(e) => {
                          setRefreshReCaptcha((r) => !r);
                          setEmergencyContactNameInvalid(e.target.value === "");
                          setEmergencyContactName(e.target.value);
                        }}
                      />
                      <FormErrorMessage>
                        The name of an emerency contact is required
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <ChakraFormControl
                      isInvalid={emergencyContactNumberInvalid}
                    >
                      <FormLabel>Emergency Contact Phone Number</FormLabel>
                      <Input
                        size="lg"
                        value={emergencyContactNumber}
                        type="number"
                        placeholder="Emergency Contact Phone Number"
                        onChange={(e) => {
                          setRefreshReCaptcha((r) => !r);
                          if (
                            e.target.value.length === 11 &&
                            !e.target.value.includes(".") &&
                            emergencyContactNumberInvalid
                          ) {
                            setEmergencyContactNumberInvalid(false);
                          }
                          setEmergencyContactNumber(e.target.value);
                        }}
                      />
                      <FormErrorMessage>
                        An emergency contact phone number is required
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <ChakraFormControl isInvalid={sessionDetailsInvalid}>
                      <FormLabel>
                        Which session do you plan on attending?
                      </FormLabel>
                      <Textarea
                        size="lg"
                        value={sessionDetails}
                        placeholder="Sunday morning swim"
                        onChange={(e) => {
                          setRefreshReCaptcha((r) => !r);
                          if (e.target.value !== "" && sessionDetailsInvalid) {
                            setSessionDetailsInvalid(false);
                          }
                          setSessionDetails(e.target.value);
                        }}
                      />
                      <FormErrorMessage>
                        Please provide us information regarding which session
                        you plan on comming to so that we can notify the correct
                        coach.
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <ChakraFormControl isInvalid={seniorJuniorInvalid}>
                      <FormLabel>Are you a senior or junior?</FormLabel>
                      <Example />
                      <FormErrorMessage>
                        Please let us know if you are a senior or junior
                      </FormErrorMessage>
                      {!seniorJuniorInvalid && (
                        <FormHelperText>
                          Seniors are 18 and over.
                        </FormHelperText>
                      )}
                    </ChakraFormControl>

                    <Button
                      isLoading={buttonDisabled}
                      loadingText="Please Wait"
                      tw="tw-w-full tw-mt-4"
                      colorScheme="red"
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      SUBMIT FORM
                    </Button>
                    <Button
                      tw="tw-mt-2 tw-w-full"
                      variant="outline"
                      colorScheme="red"
                      onClick={() => {
                        previousStep();
                      }}
                    >
                      BACK
                    </Button>
                  </>
                )}
                {step === 3 && (
                  <SuccessContainer>
                    <SuccessMessage>
                      Great - we've recieved that! 🎉
                      <br />
                      <br />
                      Thank you for completing the day membership form. We look
                      forward to welcoming you at your first session.
                      <br />
                      <br />
                      PLEASE NOTE: Attending a trial session is subject to
                      confirmation. Please make sure you're attendance has been
                      confirmed, otherwise we may not have space in the session
                      for you.
                    </SuccessMessage>
                    <Button
                      onClick={(e) => {
                        window.location.href = "/";
                      }}
                      tw="tw-w-full tw-mt-20"
                      colorScheme="red"
                      rightIcon={<ArrowForwardIcon />}
                    >
                      TAKE ME HOME
                    </Button>
                  </SuccessContainer>
                )}
              </Form>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>
      </Content>
      <GoogleReCaptchaProvider reCaptchaKey="6LfHfRApAAAAAPIC7KdWVcmGrH5Qk-2VvbLasEcg">
        <GoogleReCaptcha
          action="DayMembershipForm"
          onVerify={onVerify}
          refreshReCaptcha={refreshReCaptcha}
        />
      </GoogleReCaptchaProvider>
      <Footer />
    </Container>
  );
};
