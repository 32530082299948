import React, { useState } from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import BeatLoader from "react-spinners/BeatLoader";
import { ReactComponent as SubmitButtonIcon } from "feather-icons/dist/icons/credit-card.svg";
import { ReactComponent as GroupButtonIcon } from "feather-icons/dist/icons/users.svg";
import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useToast,
  Skeleton,
  Link,
} from "@chakra-ui/react";
import { ReactComponent as SvgDotPatternIcon } from "../../images/svg-decorator-blob-2.svg";
import { ReactComponent as ListButtonIcon } from "feather-icons/dist/icons/list.svg";
import { ReactComponent as DownloadButtonIcon } from "feather-icons/dist/icons/download.svg";
import { ReactComponent as ReportsButtonIcon } from "feather-icons/dist/icons/pie-chart.svg";
import { ReactComponent as RenewButtonIcon } from "feather-icons/dist/icons/refresh-cw.svg";
import CoachesInfo from "./privilegedViews/CoachesContainer";
import { GetMailingList } from "api/secureApi.ts";
import { GetCookie } from "api/jwt";
import MemberDetails from "./privilegedViews/MemberDetails";

const ChakraButton = tw(Button)`tw-mb-2`;
const PillSlider = tw.div`tw-overflow-visible tw-whitespace-nowrap tw-overflow-x-scroll tw-pb-2`;
const Subheading = tw.div`tw-relative tw-text-lg lg:tw-text-xl tw-font-semibold tw-inline-block`;
const Description = tw.div`tw-relative tw-mt-2`;
const Heading = tw(
  SectionHeading,
)`tw-w-full tw-text-left tw-mb-8 tw-text-gray-100`;
const Attention = tw(
  Description,
)`tw-text-primary-800 tw-font-semibold tw-mt-1 tw-mb-3`;
const SubmitButton = styled.button`
  ${tw`tw-mt-5 tw-tracking-wide tw-font-semibold tw-bg-primary-500 tw-text-gray-100 tw-px-8 tw-py-4 tw-rounded-lg hover:tw-bg-primary-900 tw-transition-all tw-duration-300 tw-ease-in-out tw-flex tw-items-center tw-justify-center focus:tw-shadow-outline focus:tw-outline-none`}
  .icon {
    ${tw`tw-w-6 tw-h-6 -tw-ml-2`}
  }
  .text {
    ${tw`tw-ml-3`}
  }
`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon,
)`tw-absolute tw-bottom-0 tw-left-0 tw-transform tw-translate-y-2/3 -tw-translate-x-1/2 -tw-z-10 tw-opacity-50 tw-text-primary-500 tw-fill-current tw-w-32`;
const InformationContainer = tw.div`tw-relative tw-w-full tw-bg-gray-100 tw-p-12 tw-mt-12 tw-rounded-xl tw-drop-shadow-2xl tw-border-gray-900 tw-border-2`;

const AdminInformationContainer = tw(
  InformationContainer,
)`tw-border-solid tw-border-4 tw-border-primary-600`;
const Pill = tw.div`tw-inline-flex tw-text-gray-800 tw-pl-4 tw-pr-4 tw-pb-2 tw-pt-2 tw-rounded tw-font-bold tw-mt-2 tw-mr-3`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon,
)`tw-absolute tw-bottom-0 tw-right-0 tw-transform tw-translate-y-2/3 tw-translate-x-1/2 -tw-z-10 tw-opacity-50 tw-text-primary-500 tw-fill-current tw-w-32`;

const SpondGroupContainer = tw.div`tw-relative tw-mt-2  sm:tw-inline-flex tw-gap-x-4`;
const HeroContainer = tw.div`tw-absolute tw-top-0 tw-left-0 tw-w-screen tw-h-screen -tw-ml-8 -tw-mt-32 -tw-z-50`;
const Hero = tw.div`tw-bg-primary-800 tw-w-full tw-h-[600px] sm:tw-h-[550px]`;
const InformationContainerParent = tw.div`tw-pt-[]`;

const InformationContainerInner = tw.div`tw-absolute tw-bg-gray-200 tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-rounded-xl`;

export default ({
  loading = true,
  firstName = "",
  isAdult = true,
  membershipNumber = "",
  memberDetails,
  setMemberDetails,
}) => {
  const toast = useToast();
  const initialFocusRef = React.useRef();
  const [downloadingSeniors, setDownloadingSeniors] = useState(false);
  const [downloadingJuniors, setDownloadingJuniors] = useState(false);
  const isMember = function () {
    return memberDetails.membershipStatus !== "NEW";
  };

  const toMembershipPayment = function () {
    if (isAdult) {
      window.location.href =
        process.env.REACT_APP_SENIORS_PAYMENT_LINK +
        "?client_reference_id=" +
        membershipNumber;
    } else {
      window.location.href =
        process.env.REACT_APP_JUNIORS_PAYMENT_LINK +
        "?client_reference_id=" +
        membershipNumber;
    }
  };

  const toMembershipDatabase = function () {
    window.location.href = "/membership-database";
  };

  const toReports = function () {
    window.location.href = "/reports";
  };

  const downloadSeniorsMailingList = function () {
    const idToken = GetCookie("idToken");
    setDownloadingSeniors(true);
    GetMailingList(idToken, "SENIOR")
      .then(() => {
        setDownloadingSeniors(false);
      })
      .catch((error) => {
        setDownloadingSeniors(false);
        toast({
          title: "Something went wrong - try again!",
          status: "error",
          isClosable: true,
        });
      });
  };

  const downloadJuniorsMailingList = function () {
    const idToken = GetCookie("idToken");
    setDownloadingJuniors(true);
    GetMailingList(idToken, "JUNIOR")
      .then(() => {
        setDownloadingJuniors(false);
      })
      .catch((error) => {
        setDownloadingJuniors(false);
        toast({
          title: "Something went wrong - try again!",
          status: "error",
          isClosable: true,
        });
      });
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeroContainer>
          <Hero></Hero>
        </HeroContainer>
        <InformationContainerParent>
          <Heading>Welcome {firstName} &#128075;</Heading>

          {!loading && (
            <>
              {isMember() && (
                <>
                  <PillSlider>
                    {memberDetails.membershipStatus === "ACTIVE" && (
                      <Pill style={{ backgroundColor: "#c1f0b2" }}>
                        ACTIVE MEMBERSHIP
                      </Pill>
                    )}
                    {memberDetails.membershipStatus === "EXPIRING" && (
                      <Pill style={{ backgroundColor: "#f5da64" }}>
                        EXPIRING MEMBERSHIP
                      </Pill>
                    )}
                    {(memberDetails.membershipStatus === "EXPIRED" ||
                      memberDetails.membershipStatus === "NEW") && (
                      <Pill style={{ backgroundColor: "#f0c1b2" }}>
                        EXPIRED MEMBERSHIP
                      </Pill>
                    )}
                    {(memberDetails.isCoach || memberDetails.isActivator) &&
                      memberDetails.coachStatus === "ACTIVE" && (
                        <Pill style={{ backgroundColor: "#c1f0b2" }}>
                          ACTIVE COACH
                        </Pill>
                      )}
                    {(memberDetails.isCoach || memberDetails.isActivator) &&
                      memberDetails.coachStatus === "EXPIRING" && (
                        <Pill style={{ backgroundColor: "#f5da64" }}>
                          EXPIRING COACH
                        </Pill>
                      )}
                    {(memberDetails.isCoach || memberDetails.isActivator) &&
                      memberDetails.coachStatus === "EXPIRED" && (
                        <Pill style={{ backgroundColor: "#f0c1b2" }}>
                          EXPIRED COACH
                        </Pill>
                      )}
                    {(memberDetails.isCoach || memberDetails.isActivator) &&
                      memberDetails.coachStatus === "NEW" && (
                        <Pill style={{ backgroundColor: "#f0c1b2" }}>
                          NEW COACH
                        </Pill>
                      )}
                  </PillSlider>

                  <InformationContainer>
                    <SvgDotPattern1 />
                    <InformationContainerInner></InformationContainerInner>
                    <Subheading>Your Spond Groups</Subheading>
                    <Description>
                      If you haven't already done so, use the link(s) below to
                      join the Spond group(s) for which you're eligible. We use
                      Spond to book and pay for sessions.
                    </Description>
                    <SpondGroupContainer>
                      {isAdult && (
                        <SubmitButton
                          onClick={(e) => {
                            window.location.href =
                              "https://group.spond.com/RVWIF";
                          }}
                          type="button"
                        >
                          <GroupButtonIcon className="icon" />
                          <span className="text">JOIN SENIORS GROUP</span>
                        </SubmitButton>
                      )}
                      {(!isAdult || memberDetails.isJuniorCoach) && (
                        <SubmitButton
                          onClick={(e) => {
                            window.location.href =
                              "https://group.spond.com/JZDVE";
                          }}
                          type="button"
                        >
                          <GroupButtonIcon className="icon" />
                          <span className="text">JOIN JUNIORS GROUP</span>
                        </SubmitButton>
                      )}
                    </SpondGroupContainer>
                  </InformationContainer>
                  <InformationContainer>
                    <SvgDotPattern2 />
                    <InformationContainerInner></InformationContainerInner>

                    <MemberDetails
                      memberDetails={memberDetails}
                      setMemberDetails={setMemberDetails}
                      sectionTitle={"Membership Details"}
                      isEditable={true}
                    ></MemberDetails>

                    <SpondGroupContainer>
                      {(memberDetails.membershipStatus === "EXPIRING" ||
                        memberDetails.membershipStatus === "EXPIRED") && (
                        <Popover
                          initialFocusRef={initialFocusRef}
                          placement="bottom"
                          closeOnBlur={false}
                        >
                          <PopoverTrigger>
                            <Button
                              style={{ marginTop: "10px" }}
                              colorScheme="red"
                              size={"lg"}
                              leftIcon={<RenewButtonIcon />}
                            >
                              RENEW
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent
                            color="white"
                            bg="blue.800"
                            borderColor="blue.800"
                          >
                            <PopoverHeader pt={4} fontWeight="bold" border="0">
                              Terms and Conditions
                            </PopoverHeader>
                            <PopoverArrow bg="blue.800" />
                            <PopoverCloseButton />
                            <PopoverBody>
                              By renewing your membership you agree to the club
                              constitution, policies and code of conduct{" "}
                              <Link
                                target="_blank"
                                href="/documents"
                                textDecoration={"underline"}
                              >
                                found here
                              </Link>
                              . Furthermore you will be auto enrolled in the
                              club championships, and your name will be provided
                              to race organisers, whose races are included in
                              the club championships. The race organiser may in
                              turn provide your race result back to the club
                              championships organiser for easy processing.
                            </PopoverBody>

                            <PopoverFooter
                              border="0"
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              pb={4}
                            >
                              <Button
                                style={{ marginTop: "10px" }}
                                colorScheme="red"
                                size={"lg"}
                                leftIcon={<RenewButtonIcon />}
                                onClick={toMembershipPayment}
                              >
                                RENEW
                              </Button>
                            </PopoverFooter>
                          </PopoverContent>
                        </Popover>
                      )}
                    </SpondGroupContainer>
                  </InformationContainer>
                </>
              )}

              {!isMember() && (
                <>
                  <Pill style={{ backgroundColor: "#f0c1b2" }}>
                    INACTIVE MEMBERSHIP
                  </Pill>
                  <InformationContainer>
                    <SvgDotPattern1 />
                    <InformationContainerInner></InformationContainerInner>
                    <Subheading>Activate your membership</Subheading>
                    <Description>
                      You need to pay your yearly membership fee to become an
                      active member. Your membership will last for 12 months
                      from the date of purchase! Please note that discount codes
                      are avaiable for students - contact us to find out more.
                      <br></br>
                      <br></br>
                      Please note - when you pay you'll be redirected to Stripe
                      to complete the purchase.
                    </Description>
                    <SpondGroupContainer>
                      <SubmitButton type="button" onClick={toMembershipPayment}>
                        <SubmitButtonIcon className="icon" />
                        <span className="text">Pay Membership</span>
                      </SubmitButton>
                    </SpondGroupContainer>
                  </InformationContainer>
                </>
              )}

              {(memberDetails.isCoach || memberDetails.isActivator) && (
                <CoachesInfo
                  memberDetails={memberDetails}
                  setMemberDetails={setMemberDetails}
                  isEditable={true}
                ></CoachesInfo>
              )}

              {memberDetails.isAdmin && (
                <AdminInformationContainer>
                  <SvgDotPattern2 />
                  <InformationContainerInner></InformationContainerInner>
                  <Subheading>Admin Section</Subheading>
                  <Attention>
                    &#9888; Personal data! Wider dissemination and downloads
                    kept for longer than absolutely nessesary could be in breach
                    of the Data Protection Act. Any lost, stolen or incorrectly
                    disseminated data must be brought to the attention of the
                    webmaster immediately - BAD TRI are under legal obligation
                    to notify the ICO within 72 hours of any such incident.
                  </Attention>
                  <SpondGroupContainer>
                    <ChakraButton
                      onClick={toMembershipDatabase}
                      colorScheme="red"
                      size={"lg"}
                      leftIcon={<ListButtonIcon />}
                    >
                      MEMBERS
                    </ChakraButton>

                    <Popover
                      initialFocusRef={initialFocusRef}
                      closeOnBlur={false}
                      placement="top"
                    >
                      <PopoverTrigger>
                        <ChakraButton
                          colorScheme="red"
                          size={"lg"}
                          leftIcon={<DownloadButtonIcon />}
                        >
                          MEMBER EXPORT
                        </ChakraButton>
                      </PopoverTrigger>
                      <PopoverContent
                        color="white"
                        bg="blue.800"
                        borderColor="blue.800"
                      >
                        <PopoverHeader pt={4} fontWeight="bold" border="0">
                          Important
                        </PopoverHeader>
                        <PopoverArrow bg="blue.800" />
                        <PopoverCloseButton />
                        <PopoverBody>
                          You're about to export bulk personal data from the
                          system. You must delete these files after use.
                        </PopoverBody>
                        <PopoverFooter
                          border="0"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          pb={4}
                        >
                          <ButtonGroup size="sm">
                            <Button
                              onClick={() => {
                                downloadJuniorsMailingList();
                                setDownloadingJuniors(true);
                              }}
                              isLoading={downloadingJuniors}
                              spinner={<BeatLoader size={8} color="black" />}
                              leftIcon={<DownloadButtonIcon />}
                            >
                              JUNIORS
                            </Button>
                            <Button
                              onClick={() => {
                                downloadSeniorsMailingList();
                                setDownloadingSeniors(true);
                              }}
                              isLoading={downloadingSeniors}
                              spinner={<BeatLoader size={8} color="black" />}
                              leftIcon={<DownloadButtonIcon />}
                              ref={initialFocusRef}
                            >
                              SENIORS
                            </Button>
                          </ButtonGroup>
                        </PopoverFooter>
                      </PopoverContent>
                    </Popover>
                    <ChakraButton
                      onClick={toReports}
                      colorScheme="red"
                      size={"lg"}
                      leftIcon={<ReportsButtonIcon />}
                    >
                      REPORTS
                    </ChakraButton>
                  </SpondGroupContainer>
                </AdminInformationContainer>
              )}
            </>
          )}
        </InformationContainerParent>

        {loading && (
          <>
            <PillSlider>
              <Skeleton>
                <Pill style={{ backgroundColor: "#c1f0b2" }}>
                  ACTIVE MEMBERSHIP
                </Pill>
              </Skeleton>
            </PillSlider>
            <Skeleton>
              <InformationContainer
                style={{ height: "300px" }}
              ></InformationContainer>
            </Skeleton>
          </>
        )}
      </ContentWithPaddingXl>
    </Container>
  );
};
