enum Gender {
  Male = "Male",
  Female = "Female",
  OTHER = "Other",
}

interface SignUpRequest {
  firstName: string;
  familyName: string;
  gender: Gender;
  emailAddress: string;
  dateOfBirth: string;
  password: string;
}

interface VerificationRequest {
  membershipNumber: string;
  confirmationCode: string;
}

interface SignInRequest {
  membershipNumber: string;
  password: string;
}

interface ContactUsRequest {
  name: string;
  emailAddress: string;
  message: string;
  token: string;
}

interface ForgotPasswordRequest {
  membershipNumber: string;
}

interface SetNewPasswordRequest {
  membershipNumber: string;
  newPassword: string;
  confirmationCode: string;
}

interface dayMembershipFormRequest {
  firstName: string;
  familyName: string;
  emailAddress: string;
  phoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  postCode: string;
  emergencyContactName: string;
  emergencyContactNumber: string;
  seniorJunior: string;
  sessionDetails: string;
  token: string;
}

const openApi: string = process.env.REACT_APP_OPEN_API_URL;
const ApiKey: string = process.env.REACT_APP_OPEN_API_KEY;

const ForgotPassword = async function (request: ForgotPasswordRequest) {
  const response = await fetch(openApi + "forgotpassword", {
    method: "POST",
    headers: {
      "x-api-key": ApiKey,
    },
    body: JSON.stringify(request),
  }).then((data) => {
    if (data.ok && data.status === 204) {
      return;
    } else {
      throw "Non 2XX response recieved";
    }
  });
  return response;
};

const SetNewPassword = async function (request: SetNewPasswordRequest) {
  const response = await fetch(openApi + "setnewpassword", {
    method: "POST",
    headers: {
      "x-api-key": ApiKey,
    },
    body: JSON.stringify(request),
  }).then((data) => {
    if (data.ok && data.status === 204) {
      return;
    } else {
      throw "Non 2XX response recieved";
    }
  });
  return response;
};

const SignUp = async function (request: SignUpRequest) {
  const response = await fetch(openApi + "signup", {
    method: "POST",
    headers: {
      "x-api-key": ApiKey,
    },
    body: JSON.stringify(request),
  }).then((data) => {
    if (data.ok && data.status === 201) {
      return data.json();
    } else {
      throw "Non 200 response recieved";
    }
  });
  return response;
};

const Verify = async function (request: VerificationRequest) {
  await fetch(openApi + "verify", {
    method: "POST",
    headers: {
      "x-api-key": ApiKey,
    },
    body: JSON.stringify(request),
  }).then((data) => {
    if (data.ok && data.status === 204) {
      return;
    } else {
      throw "Non 200 response recieved";
    }
  });
};

const SignIn = async function (request: SignInRequest) {
  const response = await fetch(openApi + "signin", {
    method: "POST",
    headers: {
      "x-api-key": ApiKey,
    },
    body: JSON.stringify(request),
  }).then((data) => {
    if (data.ok && data.status === 200) {
      return data.json();
    } else {
      throw "Non 200 response recieved";
    }
  });
  return response;
};

const DayMembershipRequest = async function (
  request: dayMembershipFormRequest,
) {
  const response = await fetch(openApi + "daymemberrequest", {
    method: "POST",
    headers: {
      "x-api-key": ApiKey,
    },
    body: JSON.stringify(request),
  }).then((data) => {
    if (data.ok && data.status === 204) {
      return;
    } else {
      throw "Non 200 response recieved";
    }
  });
  return response;
};
const ContactUs = async function (request: ContactUsRequest) {
  const response = await fetch(openApi + "contactus", {
    method: "POST",
    headers: {
      "x-api-key": ApiKey,
    },
    body: JSON.stringify(request),
  }).then((data) => {
    if (data.ok && data.status === 204) {
      return;
    } else {
      throw "Non 200 response recieved";
    }
  });
  return response;
};

const GetPublicStats = async function () {
  const response = await fetch(openApi + "statistics", {
    method: "GET",
    headers: {
      "x-api-key": ApiKey,
    },
  }).then((data) => {
    if (data.ok && data.status === 200) {
      return data.json();
    } else {
      throw "Non 200 response recieved";
    }
  });
  return response;
};

export {
  SignUp,
  Verify,
  SignIn,
  GetPublicStats,
  ContactUs,
  ForgotPassword,
  SetNewPassword,
  DayMembershipRequest,
};
