import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import colors from "tailwindcss/colors";
import { css } from "styled-components/macro";
import CoachesCards from "./CoachesCards";
import { Button } from "@chakra-ui/react";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as DollarIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as ClockIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const Container = tw.div`tw-relative tw-w-screen -tw-translate-x-8`;
const SliderContainer = tw.div`tw-relative tw-px-4 sm:tw-px-0 -tw-mt-12 tw-mb-10`;
const Content = tw.div`tw-max-w-screen-xl tw-mx-auto tw-py-16 lg:tw-py-20 lg:tw-px-12`;

const CardType1 = tw.div`tw-mt-12 tw-w-full sm:tw-w-1/2 lg:tw-w-1/3 tw-flex tw-flex-col tw-items-center`;
const Cards = tw.div`tw-flex tw-flex-wrap tw-flex-row tw-justify-center sm:tw-max-w-2xl lg:tw-max-w-5xl tw-mx-auto`;
const CardImage = styled.div`
  ${(props) => css`
    background-image: url("${props.imageSrc}");
  `}
  ${tw`tw-w-64 tw-h-64 tw-bg-cover tw-bg-center tw-rounded`}
`;
const CardContent = styled.div`
  ${tw`tw-flex tw-flex-col tw-items-center tw-mt-6`}
  .position {
    ${tw`tw-uppercase tw-font-bold tw-tracking-widest tw-text-xs tw-text-primary-500`}
  }
  .name {
    ${tw`tw-mt-1 tw-text-xl tw-font-medium tw-text-gray-900`}
  }
`;

const StyledButton = tw(Button)`tw-mx-auto tw-mt-5 tw-block`;
const HeadingWithControl = tw.div` tw-px-8 sm:tw-px-0 tw-text-center`;
const Heading = tw(SectionHeading)`tw-text-center`;
const Paragraph = tw.p`tw-text-center lg:tw-my-8 tw-text-sm lg:tw-text-base tw-font-medium tw-text-gray-600 tw-max-w-2xl tw-mx-auto`;
const Controls = tw.div`tw-flex tw-items-center tw-absolute tw-w-full tw-h-full tw-top-0`;

const ControlButtonStyling = tw.button`tw-h-full tw-px-8 tw-py-3 tw-font-bold tw-rounded-2xl tw-text-gray-900 hocus:tw-bg-gray-100 focus:tw-outline-none tw-transition tw-duration-300`;

const ControlButton = styled(ControlButtonStyling)`
  ${tw`tw-mt-4 sm:tw-mt-0 tw-p-2 tw-h-full tw-hidden lg:tw-block`}
  svg {
    ${tw`tw-w-8 tw-h-8`}
  }
`;
const PrevButton = tw(
  ControlButton,
)`tw-mr-auto lg:-tw-translate-x-12 md:-tw-translate-x-3 sm:tw-translate-x-12`;
const NextButton = tw(
  ControlButton,
)`tw-ml-auto lg:tw-translate-x-12 md:tw-translate-x-3 sm:-tw-translate-x-12`;

const CardSlider = styled(Slider)`
  ${tw`tw-mt-16 tw-overflow-hidden`}
  .slick-track {
    ${tw`tw-flex`}
  }
  .slick-slide {
    ${tw`tw-h-auto tw-flex tw-justify-center tw-mb-1`}
  }
  .slick-list {
    overflow: tw-hidden;
  }
`;

const Dots = tw.span`tw-w-1 tw-h-1 first:tw-bg-gray-500 tw-bg-gray-200 tw-rounded-full first:tw-ml-auto last:tw-mr-auto`;

const CardType2 = tw.div`tw-bg-gray-200 tw-w-full tw-h-auto tw-flex! tw-flex-col tw-border tw-max-w-sm tw-rounded-2xl tw-relative focus:tw-outline-none tw-px-4 sm:tw-px-0`;
const HorizontalLine = tw.hr`-tw-mr-4 -tw-ml-4 sm:tw-mr-0 sm:tw-ml-0 tw-border-gray-400`;
const TextInfo = tw.div`tw-py-6 sm:tw-px-10 sm:tw-py-6`;
const TextInfoTop = tw.div`tw-py-6 sm:tw-px-10 sm:tw-py-6 tw-bg-primary-900 tw-text-gray-100 tw-rounded-t-2xl -tw-ml-4 -tw-mr-4 tw-pr-4 tw-pl-4 sm:tw-ml-0 sm:tw-mr-0`;
const TitleReviewContainer = tw.div`tw-flex tw-flex-row sm:tw-justify-between tw-items-center tw-mb-6`;
const TitleReviewContainerTop = tw.div`tw-flex tw-flex-row sm:tw-justify-between tw-items-center`;
const Title = tw.h5`tw-text-2xl tw-font-bold`;
const SubTitle = tw.h6`tw-text-lg tw-font-bold`;
const Description = tw.p`tw-text-sm tw-leading-loose tw-mt-2 sm:tw-mt-4`;
const SecondaryInfoContainer = tw.div`tw-mt-2 sm:tw-mt-4`;
const IconWithText = tw.div`tw-flex tw-items-end tw-mr-6 tw-my-2`;
const Pill = tw.div`tw-text-xs tw-font-bold tw-px-3 tw-rounded tw-py-2 tw-uppercase tw-bg-primary-400 tw-text-gray-100 tw-leading-none sm:tw-mt-0 sm:tw-w-auto tw-text-center tw-ml-auto`;
const IconContainer = styled.div`
  ${tw`tw-inline-block tw-rounded-full tw-p-2 tw-bg-gray-700 tw-text-gray-100`}
  svg {
    ${tw`tw-w-3 tw-h-3`}
  }
`;
const Text = tw.div`tw-ml-2 tw-text-sm tw-font-semibold tw-text-gray-800 tw-mb-auto tw-mt-auto`;

export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const [highlightedDot, setHighlightedDot] = useState(1);

  const sessionSliderSettings = {
    infinite: true,
    arrows: false,
    dots: true,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div style={{ textAlign: "center" }}>
        <ul style={{ display: "inline-flex", gap: "5px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <Dots
        style={{
          display: "inline-flex",
          flexDirection: "col",
          fontSize: "0",
        }}
      ></Dots>
    ),
  };

  const sliderSettings = {
    infinite: true,
    arrows: false,
    dots: true,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div style={{ textAlign: "center" }}>
        <StyledButton
          style={{ display: "block" }}
          colorScheme="red"
          rightIcon={<ExternalLinkIcon />}
          variant="ghost"
          onClick={() => {
            document.location.href = "/coaches";
          }}
        >
          All Coaches and Committee
        </StyledButton>
        <ul style={{ display: "inline-flex", gap: "5px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <Dots
        style={{
          display: "inline-flex",
          flexDirection: "col",
          fontSize: "0",
        }}
      ></Dots>
    ),
  };

  const onSwipe = function (event) {
    if (event === "left") {
      moveRight();
    } else {
      moveLeft();
    }
  };

  const onPreviousClick = function (event) {
    sliderRef?.slickPrev(event);
    moveLeft();
  };

  const onNextClick = function (event) {
    sliderRef?.slickNext(event);
    moveRight();
  };

  const moveLeft = function () {
    const position = highlightedDot;

    if (position > 1) {
      setHighlightedDot(position - 1);
      resetDotColours();
      document.getElementById("top-" + (position - 1)).style.backgroundColor =
        colors.gray[400];
      document.getElementById(
        "bottom-" + (position - 1),
      ).style.backgroundColor = colors.gray[400];
    }
  };

  const moveRight = function () {
    const position = highlightedDot;

    if (position < 7) {
      setHighlightedDot(position + 1);
      resetDotColours();
      document.getElementById("top-" + (position + 1)).style.backgroundColor =
        colors.gray[400];
      document.getElementById(
        "bottom-" + (position + 1),
      ).style.backgroundColor = colors.gray[400];
    }
  };

  const resetDotColours = function () {
    for (let i = 1; i < 8; i++) {
      document.getElementById("top-" + i).style.backgroundColor =
        colors.gray[200];
      document.getElementById("bottom-" + i).style.backgroundColor =
        colors.gray[200];
    }
  };

  const coaches = [
    {
      imageSrc: "/MariaPowell.jpg",
      position1: "BTF Level 2 Triathlon Coach",
      name: "Maria Powell",
    },
    {
      imageSrc: "/PatrickNewman.jpeg",
      position1: "BTF Level 2 Triathlon Coach",
      position2: "ASA Level 2 Swim Teacher",
      name: "Patrick Newman",
    },
    {
      imageSrc: "/HannahBizoumis.jpg",
      position1: "Personal Trainer Level 3",
      position2: "Ironman Triathlon Coach",
      name: "Hannah Bizoumis",
    },
    {
      imageSrc: "/HansPeterKiekuth.jpeg",
      position1: "BTF Level 2 Triathlon Coach",
      name: "Hans-Peter Kiekuth",
    },
    {
      imageSrc: "/JennyCampbellRitchie.jpg",
      position1: "Ride Leader",
      name: "Jenny Campbell-Ritchie",
    },
    {
      imageSrc: "/AndyParsons.jpeg",
      position1: "BTF Level 2 Triathlon Coach",
      position2: "England Athletics - Running Coach",
      name: "Andy Parsons",
    },
    {
      imageSrc: "/EmmaAllum.jpg",
      position1: "BTF Level 1 Triathlon Coach",
      name: "Emma Allum",
    },
    {
      imageSrc: "/JonWaring.jpeg",
      position1: "BTF Level 2 Triathlon Coach",
      name: "Jon Waring",
    },
    {
      imageSrc: "/KateRoss.jpg",
      position1: "BTF Level 2 Triathlon Coach",
      position2: "BSc Sports, Fitness and Coaching",
      name: "Kate Ross",
    },
    {
      imageSrc: "/BenHolding.jpeg",
      position1: "BTF Level 2 Triathlon Coach",
      position2: "Previously an ASA Level 3 Swim Teacher",
      name: "Ben Holding",
    },
    {
      imageSrc: "/BruceWillerton.jpg",
      position1: "BTF Level 2 Triathlon Coach",
      name: "Bruce Willerton",
    },
    {
      imageSrc: "/ChrisHornung.jpg",
      position1: "BTF Level 2 Triathlon Coach",
      name: "Chris Hornung",
    },
    {
      imageSrc: "/JamieHinton.jpg",
      position1: "BTF Level 1 Triathlon Coach",
      name: "Jamie Hinton",
    },
  ];

  /* Change this according to your needs */
  const cards = [
    {
      title: "Monday",
      activities: [
        {
          title: "Pool Swim",
          location: "University Pool",
          description:
            "1 hour coached swimming session in summer months. Open to B/C/D squad. Book through Spond app.",
          price: "£5",
          time: "19:00 (Summer Only)",
          headline: "B/C/D Squad",
        },
      ],
    },
    {
      title: "Tuesday",
      activities: [
        {
          title: "Group Run",
          location: "Up & Running, BS6 7QA",
          description:
            "This is an uncoached run session organised and led by the lovely guys from Up & Running, which is open to BADTri members. Typically, four groups, each catering for a different ability level, will set off at 6.30pm prompt, from either outside the shop or opposite the shop. It is best to arrive about 10 minutes early. Each group runs at a steady pace through local parks and nature reserves (in summer) or pounds the pavements (in winter). Book through Spond app.",
          price: "FREE",
          time: "18:30",
        },
      ],
    },
    {
      title: "Wednesday",
      activities: [
        {
          title: "Pool Swim",
          location: "Horfield Leisure Centre",
          description:
            "1 hour coached swimming session. Open to all squads. Book through Spond app.",
          price: "£5",
          time: "06:30",
          headline: "All squads",
        },
        {
          title: "Track",
          location: "SGS College, BS34 8LP",
          description:
            "An action-packed 75-minute track session for all abilities (seniors only) at the well-appointed athletics facilities of SGS College. The venue is between the MOD in Filton and Bristol Park Way station. The session is jointly coached by Bristol & West Athletics Club and BADTri coaches. Book through Spond app.",
          price: "£5",
          time: "18:15",
          headline: "Seniors only",
        },
        {
          title: "Pool Swim",
          location: "University Pool",
          description:
            "1 hour coached swimming session. Open to all squads. Book through Spond app.",
          price: "£5",
          time: "19:00",
          headline: "All squads",
        },
      ],
    },
    {
      title: "Thursday",
      activities: [
        {
          title: "Pool Swim",
          location: "University Pool",
          description:
            "1 hour coached swimming session. Open A/B squad. Book through Spond app.",
          price: "£5",
          time: "06:30",
          headline: "A/B Squad",
        },
        {
          title: "Fast Ride",
          location: "Pump House, BS8 4PZ",
          description:
            "A quick ride, finishing with a chance for a drink and a chat at a local watering hole. This is an unled ride that follows a tw-fixed route, during British Summer Time only. Average speed 19-20mph. Book through Spond app.",
          price: "FREE",
          time: "18:00",
        },
        {
          title: "Track",
          location: "Whitehall Athletics Track",
          description:
            "A 75-minute running track session hosted by Bristol & West Athletics Club at Whitehall Athletic Track. This session focuses on speed and endurance. Book a place through the Spond app for free - but pay at the track.",
          price: "£5",
          time: "19:10",
          headline: "Seniors only",
        },
      ],
    },
    {
      title: "Friday",
      activities: [
        {
          title: "No Sessions",
          description:
            "There are currently no club sessions on a Friday - but our members are always heading out, so there's bound to be something going on!",
        },
      ],
    },
    {
      title: "Saturday",
      activities: [
        {
          title: "Intro Ride",
          location: "Ashton Court",
          description:
            "This ride is intended to introduce senior members to group riding. Each introductory ride will be led by an experienced club appointed leader, will proceed at the pace of the slowest rider and is intended to be manageable in terms of distance and difficulty. Distance can vary between 25km and 50km. Book through Spond app.",
          price: "FREE",
          time: "09:00",
        },
        {
          title: "Bike & Swim",
          location: "Henbury Leisure Centre",
          description:
            "This session is a one hour bike session followed by a one hour swim session. It is intended for our junior members. Book through Spond app.",
          price: "£4",
          time: "14:30",
          headline: "Juniors only",
        },
      ],
    },
    {
      title: "Sunday",
      activities: [
        {
          title: "Pool Swim",
          location: "University Pool",
          description:
            "1 hour coached swimming session. Open to all squads. Book through Spond app.",
          price: "£5",
          time: "07:50",
          headline: "all squads",
        },
        {
          title: "Open Water Swim",
          location: "Henleaze Lake",
          description:
            "1 hour open water swimming session. Open to all squads. Book through Spond app.",
          price: "£5",
          time: "09:30 (May - September)",
          headline: "Seniors Only",
        },
      ],
    },
  ];

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Our Sessions</Heading>
          <Paragraph>
            Find below a selection of our regular weekly sessions and the
            coaches who'll be running them. We use Spond to manage session
            bookings - To see all of our socials, sessions and races join us and
            get access to a whole load of other events.
          </Paragraph>
        </HeadingWithControl>

        <SliderContainer>
          <CardSlider ref={setSliderRef} {...sliderSettings}>
            {coaches.map((card, index) => (
              <CardType1 key={index}>
                <CardImage imageSrc={card.imageSrc} />
                <CardContent>
                  <span className="position">{card.position1}</span>
                  <span className="position">{card.position2}</span>
                  <span className="name">{card.name}</span>
                </CardContent>
              </CardType1>
            ))}
          </CardSlider>
        </SliderContainer>

        <SliderContainer tw="tw-mt-10">
          <CardSlider ref={setSliderRef} {...sessionSliderSettings}>
            {cards.map((card, index) => (
              <CardType2 key={index}>
                <TextInfoTop>
                  <TitleReviewContainerTop>
                    <Title>{card.title}</Title>
                  </TitleReviewContainerTop>
                </TextInfoTop>
                <>
                  {card.activities.map((activity) => (
                    <>
                      <HorizontalLine />
                      <TextInfo>
                        <SecondaryInfoContainer>
                          <TitleReviewContainer>
                            <SubTitle>{activity.title}</SubTitle>
                            {activity.headline && (
                              <Pill>{activity.headline}</Pill>
                            )}
                          </TitleReviewContainer>
                        </SecondaryInfoContainer>
                        <SecondaryInfoContainer>
                          {activity.location && (
                            <IconWithText>
                              <IconContainer>
                                <LocationIcon />
                              </IconContainer>
                              <Text>{activity.location}</Text>
                            </IconWithText>
                          )}
                          {activity.time && (
                            <IconWithText>
                              <IconContainer>
                                <ClockIcon />
                              </IconContainer>
                              <Text>{activity.time}</Text>
                            </IconWithText>
                          )}
                          {activity.price && (
                            <IconWithText>
                              <IconContainer>
                                <DollarIcon />
                              </IconContainer>
                              <Text>{activity.price}</Text>
                            </IconWithText>
                          )}
                        </SecondaryInfoContainer>
                        <Description>{activity.description}</Description>
                      </TextInfo>
                    </>
                  ))}
                </>
              </CardType2>
            ))}
          </CardSlider>
        </SliderContainer>
      </Content>
    </Container>
  );
};
