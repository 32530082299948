import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/header/Header.js";
import Slider from "components/cards/ThreeColSlider.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Slider />
      <Footer />
    </AnimationRevealPage>
  );
};
